<template>
  <div class="view" aria-hidden="true" :class="{'-noHeader': hideHeader}">
    <transition name="fade">
      <gmap-map :center="{ lat: 47.055307, lng: 6.740447 }" :options="mapStyle" ref="mapRef" v-if="mapStyle">
        <!-- gmap-cluster ref="cluster" :grid-size="100" :max-zoom="18" :zoom-on-click="true" :styles="mapStyle.clusterStyle" -->
        <template v-for="(marker, key) in eventsFiltered">
          <gmap-marker
              v-if="marker.latlng.lat && marker.latlng.lng"
              :key="key + '-' + marker.id"
              :position="{
                lat: parseFloat(marker.latlng.lat),
                lng: parseFloat(marker.latlng.lng)
              }"
              :clickable="true"
              :icon="{ url: mapStyle.clusterStyle[0].url }"
              @click="onClickMarker(marker, 'events')"
          />
        </template>
        <template v-for="(marker, key) in contentsFiltered">
          <gmap-marker
              v-if="marker.latlng.lat && marker.latlng.lng"
              :key="key + '-' + marker.id"
              :position="{
                lat: parseFloat(marker.latlng.lat),
                lng: parseFloat(marker.latlng.lng)
              }"
              :clickable="true"
              :icon="{ url: mapStyle.clusterStyle[0].url }"
              @click="onClickMarker(marker, 'contents')"
          />
        </template>
        <!-- /gmap-cluster -->
      </gmap-map>
    </transition>
  </div>
</template>

<script>
// import GmapCluster from 'vue2-google-maps/dist/components/cluster'

export default {
  name: 'Map',
  props: ['eventsFiltered', 'mapStyle', 'asideJson', 'navInHeader', 'events', 'contentsFiltered'],
  components: {
    // GmapCluster
  },
  watch: {
    eventsFiltered (events) {
      if (events.length) {
        if (window && window.google) {
          const bounds = new window.google.maps.LatLngBounds()
          for (let i = 0; i < events.length; i++) {
            bounds.extend({
              lat: parseFloat(events[i].latlng.lat),
              lng: parseFloat(events[i].latlng.lng)
            })
          }
          this.$refs.mapRef.fitBounds(bounds)
          setTimeout(() => {
            // this.$refs.cluster.$clusterObject.repaint()
          }, 400)
        }
      }
    },
    contentsFiltered (contents) {
      if (contents.length) {
        if (window && window.google) {
          const bounds = new window.google.maps.LatLngBounds()
          for (let i = 0; i < contents.length; i++) {
            bounds.extend({
              lat: parseFloat(contents[i].latlng.lat),
              lng: parseFloat(contents[i].latlng.lng)
            })
          }
          this.$refs.mapRef.fitBounds(bounds)
          setTimeout(() => {
            // this.$refs.cluster.$clusterObject.repaint()
          }, 400)
        }
      }
    }
  },
  computed: {
    hideHeader () {
      return (this.asideJson != null && this.asideJson.length == 0 && this.navInHeader) || (this.eventsFiltered != null && this.events != null && this.navInHeader && this.eventsFiltered.length == this.events.length)
    }
  },
  methods: {
    onClickMarker (marker, type) {
      /*
      let continentItem = this.asideJson.filter((i) => i['id'] === marker.continent_id)
      let countryItem = continentItem.length && continentItem[0].countries != undefined ? continentItem[0].countries.filter((i) => i['id'] === marker.country_id) : []
      let regionItem = countryItem.length && countryItem[0].regions != undefined ? countryItem[0].regions.filter((i) => i['id'] === marker.region_id) : []

      if (regionItem.length && regionItem[0].id != '') {
        this.$emit('filterEvents', { 'type': 'region', 'event': { 'id': regionItem[0].id, 'name': regionItem[0].name } })
      } else {
        this.$emit('filterEvents', { 'type': 'country', 'event': { 'id': countryItem[0].id, 'name': countryItem[0].name } })
      }
      this.$emit('selectedMarker', marker)
      */

      this.tcEvent(marker)

      if (type === 'contents') {
        this.$router.push({ path: '/contents', query: { content: marker.id } })
      } else {
        this.$router.push({ path: window.progData.calendar ? '/calendar' : '/list', query: { event: marker.id } })
      }
    },
    tcEvent (event) {
      let continentItem = this.asideJson.find((i) => i.id === event.continent_id)
      let countryItem = continentItem ? continentItem.countries.find((i) => i.id === event.country_id) : undefined
      let regionItem = countryItem ? countryItem.regions.find((i) => i.id === event.region_id) : undefined

      let label = ''
      label = countryItem ? countryItem.name : 'undefined'
      label += regionItem ? '_' + regionItem.name : 'undefined'

      window.tc_events_global(this, 'map_click', {
        evt_category: 'map',
        evt_button_action: 'map_click',
        evt_button_label: label
      })

      console.log('TAGCO => tc_events_global: map_click', {
        evt_category: 'map',
        evt_button_action: 'map_click',
        evt_button_label: label
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  height: calc(100% - 15rem);
  opacity: 0.9;

  &.-noHeader {
    height: 100%;
  }

  .vue-map-container {
    width: 100%;
    height: 100%;
    outline: none;
  }
}
</style>
