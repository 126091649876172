<template>
  <transition name="fade">
    <aside ref="aside" :class="{ 'hide': toggle }" v-if="asideJson">
      <button class="aside-toggle" type="button" aria-hidden="true" @click="toggleAside(false)"><span>••••</span></button>
      <ul class="level-0">
        <li class="level-0" :class="{'active': !selected_continent && !selected_country && !selected_region && !allView, '-only': !locals.length}" v-if="hasGlobal">
          <button type="button" @click="activeList('g')"><i class="icon-world"></i> {{ $t('Global view') }}</button>
        </li>
        <li class="level-0" :class="{'active': selected_continent || selected_country || selected_region}" v-if="locals.length">
          <span @click="toggleAside(true)"><i class="icon-marker"></i> {{ $t('Local programs') }}</span>
          <ul class="level-1">
            <li class="level-1" v-for="continent in asideJson" :key="continent.id">
              <button type="button" @click="activeList('c', continent, true)" :class="{'active': selected_continent === continent.id || collapse_continent === continent.id }">{{ continent.name }} <span class="nb">{{ continent.events }}</span></button>
              <slide-up-down :active="selected_continent === continent.id || collapse_continent === continent.id" :duration="200">
                <ul class="level-2" v-if="continent.countries">
                  <li class="level-2" v-for="country in continent.countries" :key="country.id" :class="{'collapse': country.regions && country.regions.length}">
                    <button type="button" @click="activeList('p', country, country.regions)" :class="{'active': selected_country === country.id  || collapse_country === country.id }">{{ country.name }}</button>
                    <slide-up-down :active="selected_country === country.id || collapse_country === country.id" :duration="200">
                      <ul class="level-3" v-if="country.regions">
                        <li class="level-3" v-for="region in country.regions" :key="region.id">
                          <button type="button" @click="activeList('r', region)" :class="{'active': selected_region === region.id }">{{ region.name }}</button>
                        </li>
                      </ul>
                    </slide-up-down>
                  </li>
                </ul>
              </slide-up-down>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  </transition>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'Aside',
  components: {
    SlideUpDown
  },
  props: ['asideJson', 'events', 'contentsJson', 'allView'],
  data() {
    return {
      toggle: false,
      locals: false,
      hasGlobal: false,
      selected_continent: null,
      selected_country: null,
      selected_region: null,
      collapse_country: null,
      collapse_continent: null
    }
  },
  watch: {
    events () {
      this.getLocals()
    },
    '$route' (to) {
      setTimeout(() => {
        if (to.query.event || to.query.content) {
          let event
          if (to.query.event) {
            event = this.events.find((id) => id.id === to.query.event)
          }
          if (to.query.content) {
            event = this.contentsJson.find((id) => id.id === to.query.content)
          }
          if (event && event.global) {
            this.activeList('g')
          } else {
            this.collapse_country = event.country_id
            this.collapse_continent = event.continent_id
            if (event.region_id) {
              this.selected_region = event.region_id
              this.filterCountries('region', this.asideJson.find((continent) => continent.id === event.continent_id).countries.find((country) => country.id === event.country_id).regions.find((region) => region.id === event.region_id))
            } else {
              this.selected_country = event.country_id
              this.filterCountries('country', this.asideJson.find((continent) => continent.id === event.continent_id).countries.find((country) => country.id === event.country_id))
            }
          }
        }
      }, 50)
    },
    allView (reset) {
      if (reset) {
        this.selected_region = null
        this.selected_country = null
        this.selected_continent = null
        this.collapse_country = null
        this.collapse_continent = null
      }
    }
  },
  mounted () {
    if (window.innerWidth < 1024) {
      this.toggleAside(true)
    }
    this.getLocals()
  },
  methods: {
    toggleAside (e) {
      this.$emit('toggleAside', this.toggle = e ? false : !this.toggle)
    },
    activeList (type, event, collapse) {
      switch (type) {
        case 'g':
          if (this.locals.length) {
            this.toggleAside(true)
          }
          this.filterCountries('global', null)
          this.selected_region = null
          this.selected_country = null
          this.selected_continent = null
          this.collapse_country = null
          this.collapse_continent = null
          break;

        case 'c':
          if (collapse) {
            this.collapse_continent = this.collapse_continent !== event.id ? event.id : null
          } else {
            this.selected_continent = event.id
            this.collapse_continent = event.id
            if (this.selected_continent) {
              this.filterCountries('continent', event)
            } else {
              this.filterCountries('global', null)
            }
            this.selected_region = null
            this.selected_country = null
          }
          break;

        case 'p':
          if (collapse) {
            this.collapse_country = this.collapse_country !== event.id ? event.id : null
          } else {
            this.selected_country = event.id
            this.collapse_country = event.id
            this.filterCountries('country', event)
            this.selected_region = null
          }
          break;

        case 'r':
          this.filterCountries('region', event)
          this.selected_country = null
          this.selected_region = event.id
          this.tcEvent()
          break;
      }
    },
    filterCountries (type, event) {
      this.$emit('filterEvents', {'type': type, 'event': event})
      this.$emit('filterContents', {'type': type, 'event': event})
    },
    getLocals () {
      this.locals = this.events.filter((e) => e.global === false)
      this.hasGlobal = this.locals.length != this.events.length
      if (!this.locals.length) {
        this.locals = this.contentsJson.filter((e) => e.global === false)
        this.hasGlobal = this.hasGlobal || this.locals.length != this.contentsJson.length
      }
      this.toggle = !this.locals.length
      this.$emit('toggleAside', this.toggle)
    },
    tcEvent() {
      let continentItem = this.asideJson.find((i) => i.id === this.collapse_continent)
      let countryItem = continentItem ? continentItem.countries.find((i) => i.id === this.collapse_country) : undefined
      let regionItem = countryItem ? countryItem.regions.find((i) => i.id === this.selected_region) : undefined

      let label = ''
      label = countryItem ? countryItem.name : 'undefined'
      label += regionItem ? '_' + regionItem.name : 'undefined'

      window.tc_events_global(this, 'localProgram_click', {
        evt_category: 'localProgram',
        evt_button_action: 'localProgram_click',
        evt_button_label: label
      })

      console.log('TAGCO => tc_events_global: localProgram_click ', {
        evt_category: 'localProgram',
        evt_button_action: 'localProgram_click',
        evt_button_label: label
      })
    }
  }
}
</script>

<style lang="scss" scoped>

aside {
  position: absolute;
  z-index: 20;
  width: 25%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 0 3px 0 rgba(0,0,0,0.1);

  @include bp-down(lg) {
    width: 100%;
    height: auto;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
  }

  &.hide {
    transform: translateX(calc(-100% + 4rem));

    @include bp-down(lg) {
      transform: none;
    }
    ul.level-0 {
      border-bottom: 1px solid transparent;

      li.level-0.-only {
        border-bottom: 1px solid #E5E5E5;
      }

      @include bp-down(lg) {
        max-height: 0;
        border-bottom: none;
      }
    }

    ul.level-1 {
      border-top: 1px solid #E5E5E5;
    }

    li.level-0 {
      transform: translateX(calc(100% - 6rem));

      @include bp-down(lg) {
        transform: none;
      }
      & > span, & > button {
        padding-left: 6.4rem;
        margin-left: 0;
        cursor: pointer;

        @include bp-down(lg) {
          padding-left: 2.4rem;
          margin-left: 2rem;
        }

        i {
          left: 4rem;

          @include bp-down(lg) {
            left: 0.6rem;
          }
        }
      }
    }
    li.level-1 {
      opacity: 0;
      @include bp-down(lg) {
        opacity: 1;
      }
    }

    .aside-toggle {
      cursor: e-resize;
    }

  }

  .aside-toggle {
    z-index: 20;
    position: absolute;
    cursor: w-resize;
    background: white;
    top: 30%;
    right: .1rem;
    padding: 0;
    width: 2rem;
    height: 6rem;
    border: none;
    border-radius: 0 4px 4px 0;
    box-shadow: 3px 0 3px 0 rgba(0,0,0,0.1);
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
    outline: none;

    @include bp-down(lg) {
      top: 100%;
      left: 50%;
      right: 0;
      width: 6rem;
      height: 2rem;
      transform: translateX(-50%);
      border-radius: 0 0 4px 4px;
      box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
    }

    span {
      position: absolute;
      top: 52%;
      left: 50%;
      display: block;
      opacity: 0.3;
      color: black;
      font-size: 14px;
      letter-spacing: 2px;
      transition: all 0.2s ease-in-out;
      transform: translate(-50%, -50%) rotate(90deg);

      @include bp-down(lg) {
        top: 50%;
        left: 54%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover, &:focus {
      width: 2.6rem;

      @include bp-down(lg) {
        width: 6rem;
      }
      span {
        opacity: 1;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.level {
      &-0 {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        padding: 5rem 0 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 1px solid $border;
        @include bp-down(lg) {
          padding: 0;
          max-height: 150rem;
          border-bottom: none;
          transition: all 0.4s ease-in;
        }
      }
      &-1 {
        background: white;
        border-top: 1px solid transparent;
        transition: all 0.2s ease-in-out;
      }
      &-2 {
        padding-bottom: 1rem;
        margin-right: 2rem;
        &.active {
          max-height: 150rem;
          margin-bottom: 1rem;
        }
      }
      &-3 {
        &.active {
          max-height: 50rem;
        }
      }
    }

    li {

      & > button, & > span {
        position: relative;
        border: none;
        display: block;
        text-align: left;
        background: none;
        color: $primary;
        outline: none;
        transition: all 0.2s ease-in-out;

        i {
          position: absolute;
          top: 1.3rem;
          left: .6rem;
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out;
        }
      }

      & > button {
        cursor: pointer;
      }

      &.level {

        &-0 {
          transition: all 0.2s ease-in-out;
          border-top: 1px solid $border;


          &.-only {
            border-bottom: 1px solid transparent;
          }

          &.active {
            background: #FAFAFB;
          }

          & > button, & > span {
            padding: 1rem 2rem 1rem 2.4rem;
            width: calc(100% - 4rem);
            margin-left: 4rem;
            font-weight: 300;
            font-size: em(22);
            line-height: em(28, 22);

            @include bp-down(lg) {
              margin-left: 2rem;
              font-size: em(18);
              line-height: em(22, 18);
            }
          }
        }

        &-1 {
          padding-left: 4rem;
          transition: all 0.2s ease-in-out;
          border-top: 1px solid $border;

          @include bp-down(lg) {
            padding-left: 2rem;
          }

          & > button {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 1.5rem 2rem 1.6rem 2.4rem;
            font-weight: 400;
            text-transform: uppercase;

            @include bp-down(lg) {
              padding: 1.1rem 2rem 1.1rem 2.4rem;
            }

            &:before {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              width: .8rem;
              height: .8rem;
              border-top: 1px solid $primary;
              border-right: 1px solid $primary;
              transform: translateY(-50%) rotate(45deg);
              transition: all 0.2s ease-in-out;
            }

            &:hover {
              color: black;
              &:before {
                border-top: 1px solid black;
                border-right: 1px solid black;
              }
            }

            &.active:before {
              left: 1%;
              transform: translateY(-80%) rotate(135deg);
            }

            span {
              font-weight: 300;
            }
          }
        }

        &-2 {

          & > button {
            padding: 1rem 3rem 1rem 2.4rem;
            width: 100%;
            font-weight: 300;
            border-radius: 2.5rem;
            background: transparent;

            &:before {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              border-radius: 2.5rem;
              transition: all 0.2s ease-in-out;
              background: $secondary-lighter;
            }

            &:after {
              position: absolute;
              display: block;
              content: '•';
              right: 1.6rem;
              top: 50%;
              opacity: 0;
              color: $secondary;
              transform: translateY(-50%);
              transition: all 0.2s ease-in-out;
            }


            &:hover {
              color: black;
            }

            &.active {

              &:before {
                opacity: 0.15;
              }
              &:after {
                opacity: 1;
              }
            }
          }

          &.collapse {
            & > button {
              &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: .8rem;
                height: .8rem;
                opacity: 1;
                border-radius: 0;
                background: none;
                border-top: 1px solid $primary;
                border-right: 1px solid $primary;
                transform: translateY(-50%) rotate(45deg);
                transition: all 0.2s ease-in-out;
              }

              &:hover {
                &:before {
                  border-top: 1px solid black;
                  border-right: 1px solid black;
                }
              }

              &.active {
                color: black;
                background: none;
                &:after {
                  opacity: 0;
                }
                &:before {
                  left: 1%;
                  border-top: 1px solid black;
                  border-right: 1px solid black;
                  transform: translateY(-80%) rotate(135deg);
                }

              }
            }
          }
        }

        &-3 {
          position: relative;

          &:before {
            position: absolute;
            display: block;
            content: '•';
            left: 2rem;
            top: 50%;
            opacity: 0.3;
            color: #485C74;
            transform: translateY(-50%);
            transition: all 0.2s ease-in-out;
          }

          & > button {
            padding: 1rem 3rem 1rem 4rem;
            width: 100%;
            font-weight: 300;
            border-radius: 2.5rem;
            background: transparent;

            &:before {
              display: block;
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              border-radius: 2.5rem;
              transition: all 0.2s ease-in-out;
              background: $secondary-lighter;
            }

            &:after {
              position: absolute;
              display: block;
              content: '•';
              right: 1.6rem;
              top: 50%;
              opacity: 0;
              color: $secondary;
              transform: translateY(-50%);
              transition: all 0.2s ease-in-out;
            }

            &:hover {
              color: black;
              &:before {
                color: black;
              }
            }

            &.active {

              &:before {
                opacity: 0.15;
              }

              &:after {
                opacity: 1;
              }
            }

          }
        }
      }

    }
  }
}
</style>
