<template>
  <header>
    <img alt="Somfy" :src="logo" @click="$emit('resetView')">
    <div>
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
    </div>
    <nav ref="nav" :style="'--left: ' + selectorLeft + '; --width: ' + selectorWidth" v-if="navInHeader">
      <router-link to="/map" v-if="map" class="link-map"><i class="icon-maps"></i></router-link>
      <router-link to="/calendar" v-if="calendar && eventsFiltered != null && eventsFiltered.length" class="link-calendar"><i class="icon-calendar"></i></router-link>
      <router-link to="/list" v-if="eventsFiltered != null && eventsFiltered.length"><i class="icon-list"></i></router-link>
      <router-link to="/contents" v-if="contentsFiltered.length && (contentsFiltered.length !== contentsJson.length || contentsJson.length === contentsFiltered.filter((e) => e.global).length || contentsFiltered.filter((e) => e.global).length === 0)"><i class="icon-info_small"></i><i class="badge">{{ contentsFiltered.length }}</i></router-link>
    </nav>
    <select name="lang" v-model="$i18n.locale" v-if="language">
      <option v-for="(lang, i) in locale" :key="`Lang${i}`" :value="lang.value">
        {{ lang.name }}
      </option>
    </select>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: ['contentsFiltered', 'contentsJson', 'eventsFiltered', 'calendar', 'map', 'navInHeader'],
  watch: {
    '$route' () {
      setTimeout(() => {
        this.moveSelector()
      }, 100)
    },
    '$i18n.locale' () {
      this.logo = window.progData.text[this.$i18n.locale].logo
      this.title = window.progData.text[this.$i18n.locale].title
      this.subtitle = window.progData.text[this.$i18n.locale].subtitle
    }
  },
  data () {
    return {
      logo: '',
      title: '',
      subtitle: '',
      language: '',
      selectorWidth: '50%',
      selectorLeft: '25%',
      locale: [
        {
          name: 'English',
          value: 'en-US'
        },
        {
          name: 'Local language',
          value: 'lo-LA'
        }
      ]
    }
  },
  mounted () {
    this.logo = window.progData.text[this.$i18n.locale].logo
    this.title = window.progData.text[this.$i18n.locale].title
    this.subtitle = window.progData.text[this.$i18n.locale].subtitle
    this.language = window.progData['language-selector']
    setTimeout(() => { this.moveSelector() }, 100)
  },
  methods: {
    moveSelector (el) {
      if(this.$refs.nav || el){
        if (el) {
          this.selectorWidth = el.target.offsetWidth + 'px'
          this.selectorLeft = el.target.offsetLeft + 'px'
        } else {
          let el = this.$refs.nav.querySelector('.router-link-active')
          if (el) {
            this.selectorWidth = el.offsetWidth + 'px'
            this.selectorLeft = el.offsetLeft + 'px'
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

header {
  z-index: 10;
  display: flex;
  align-items: center;
  height: 15rem;
  padding: 0 4rem;
  background: white;
  box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.1);

  @include bp-down(lg) {
    flex-wrap: wrap;
    padding: 2rem 2rem;
    height: auto;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }

  nav {
    position: relative;
    display: flex;
    padding: .5rem;
    background: #fafafb;
    border-radius: 2.5rem;
    margin-left: auto;
    margin-right: 15px;

    @include bp-down(lg) {
      display: none;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: .5rem;
      bottom: .5rem;
      border-radius: 2.5rem;
      left: var(--left);
      width: var(--width);
      transition: all 0.2s ease-in-out;
      background-color: $secondary;
      opacity: 0.1;
    }

    a {
      position: relative;
      display: block;
      padding: 1rem 2rem;
      color: $primary;
      opacity: 0.5;
      font-size: em(22);

      i {
        pointer-events: none;

        &.badge {
          position: absolute;
          top: 34%;
          left: 35%;
          font-style: normal;
          font-size: em(8, 22);
          font-weight: 500;
          border-radius: 100%;
          width: 1.2rem;
          height: 1.2rem;
          text-align: center;
          padding-top: .1rem;
          line-height: 1.2rem;
          color: white;
          background-color: $primary;
          transform: translate(-50%, -50%);
        }
      }

      &:hover, &.router-link-exact-active, &.router-link-active {
        opacity: 1;
      }
    }
  }

}
img {
  width: 18rem;
  margin-right: 4rem;
  cursor: pointer;

  @include bp-down(lg) {
    width: 12rem;
    margin-right: auto;
    margin-bottom: 2rem;
  }
}
div {
  flex-grow: 1;
  margin-right: 4rem;
  @include bp-down(lg) {
    order: 2;
    width: 100%;
    margin-right: 0;
  }
}
h1 {
  color: $primary;
  font-weight: 300;
  font-size: em(36);
  line-height: em(40, 36);
  margin-bottom: 1rem;
  @include bp-down(lg) {
    font-size: em(26);
    line-height: em(30, 26);
  }
}
h2 {
  color: $primary;
  font-weight: 400;
  font-size: em(16);
  line-height: em(19, 16);
  margin-bottom: 0;
}
select {
  cursor: pointer;
  border: none;
  color: #1A1B1C;
  font-weight: 300;
  font-size: em(16);
  line-height: em(19, 16);
  padding: 0 0 0 1.2rem;
  //border-left: 1px solid $border;
  @include bp-down(lg) {
    margin-bottom: 2rem;
  }
}
</style>
