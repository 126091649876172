<template>
  <div id="app" :style="varCSS">
    <Header @resetView="() => { resetView() }" :contentsFiltered="contentsFiltered" :eventsFiltered="eventsFiltered" :calendar="calendar" :map="map" :navInHeader="navInHeader" :contentsJson="contentsJson"/>
    <main>
      <transition name="fade">
        <Aside v-if="aside" :asideJson="asideJson" :events="events" :contentsJson="contentsJson" :allView="eventsFiltered.length === events.length" @toggleAside="(e) => { viewsFull = e }" @filterEvents="(e) => { filterEvents = e }" />
      </transition>
      <div class="views" :class="{'-full': viewsFull, '-only': !aside}" :style="'background-image: url(' + background + ')'">
        <transition name="slide">
          <header :class="{'-withContents': contentsFiltered.length, '-noHeader': smallHeader}" v-if="!hideHeader">
            <transition name="fade">
              <div class="title" v-if="eventsFiltered.length !== events.length">{{ $t('view', { 'filter': filterEvents.event ? filterEvents.event.name : $t('global') }) }}</div>
            </transition>
            <nav ref="nav" :style="'--left: ' + selectorLeft + '; --width: ' + selectorWidth" v-if="!navInHeader">
              <router-link to="/map" v-if="map" class="link-map"><i class="icon-maps"></i></router-link>
              <router-link to="/calendar" v-if="calendar && eventsFiltered != null && eventsFiltered.length" class="link-calendar"><i class="icon-calendar"></i></router-link>
              <router-link to="/list" v-if="eventsFiltered != null && eventsFiltered.length"><i class="icon-list"></i></router-link>
              <router-link to="/contents" v-if="contentsFiltered.length && contentsFiltered.length !== contentsJson.length"><i class="icon-info_small"></i><i class="badge">{{ contentsFiltered.length }}</i></router-link>
            </nav>
          </header>
        </transition>
        <transition name="fade">
          <router-view :calendarJson="calendarJson" :contentsJson="contentsJson" :asideJson="asideJson" :contentsFiltered="contentsFiltered" :eventsFiltered="eventsFiltered" :events="events" :filterEvents="filterEvents" @filterEvents="(e) => { filterEvents = e }" @filterContents="(e) => { filterEvents = e }" :mapStyle="mapStyle" :navInHeader="navInHeader"/>
        </transition>
      </div>
    </main>
    <transition name="fade">
      <div class="flower-spinner" v-if="loading !== 0">
        <div class="dots-container">
          <div class="bigger-dot">
            <div class="smaller-dot"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Aside from './components/Aside.vue'

export default {
  name: 'App',
  components: {
    Header,
    Aside
  },
  data() {
    return {
      loading: 10,
      varCSS: '',
      allEvents: true,
      viewsFull: false,
      background: '',
      selectorWidth: '50%',
      selectorLeft: '25%',
      calendarJson: null,
      selectedMarker: null,
      filterEvents: { event: null, type: 'global' },
      eventsFiltered: [],
      contentsFiltered: [],
      events: [],
      map: true,
      calendar: false,
      navInHeader: true,
      aside: false,
      asideJson: null,
      contentsJson: null,
      mapStyle: null
    }
  },
  watch: {
    '$route' () {
      this.callTagCo()
      setTimeout(() => {
        this.moveSelector()
      }, 100)
    },
    '$i18n.locale' () {
      this.callTagCo()
      this.callJson(false)
    },
    filterEvents () {
      this.refreshEventsFiltered()
    }
  },
  computed: {
    hideHeader() {
        return (this.asideJson != null && this.asideJson.length === 0 && this.navInHeader && this.$route.name === 'Map') || (this.eventsFiltered != null && this.events != null && this.navInHeader && this.eventsFiltered.length === this.events.length && this.$route.name === 'Map');
    },
    smallHeader() {
        return this.asideJson != null && this.asideJson.length === 0 && this.navInHeader;
    }
  },
  mounted () {
    this.callJson(true)

    setTimeout(() => {
      this.moveSelector()

      if (window.innerWidth < 1024 && this.$route.name !== 'List') {
        this.$router.push({ path: '/list'})
      }

      if (window && window.progData) {
        if (window.progData.background) {
          this.background = window.progData.background
        }
        if (window.progData.locale) {
          this.$i18n.locale = window.progData.locale
        }
        if (window.progData.colors) {
          this.varCSS = '--primary: ' + window.progData.colors.primary + '; --primary-lighter: ' + window.progData.colors["primary-lighter"] + '; --secondary: ' + window.progData.colors.secondary + '; --secondary-lighter: ' + window.progData.colors["secondary-lighter"] + '; --tertiary: ' + window.progData.colors.tertiary
        }
        this.map = window.progData.map
        this.calendar = window.progData.calendar
        this.navInHeader = window.progData.navInHeader
      }
    }, 100)
  },
  methods: {
    callJson(once) {
      this.loading = 4
      this.axios
          .get(window.progData.json.calendar + '&locale=' + this.$i18n.locale)
          //.get(window.progData.json.calendar)
          .then(response => this.loadedCalendarData(response.data, once))
          .catch(error => console.log(error))
      this.axios
          .get(window.progData.json.countries + '&locale=' + this.$i18n.locale)
          //.get(window.progData.json.countries)
          .then(response => this.loadedAsideData(response.data))
          .catch(error => console.log(error))
      this.axios
          .get(window.progData.json.mapStyle)
          .then(response => this.loadedMapStyleData(response.data))
          .catch(error => console.log(error))
      this.axios
          .get(window.progData.json.contents + '&locale=' + this.$i18n.locale)
          //.get(window.progData.json.contents)
          .then(response => this.loadedContentsData(response.data, once))
          .catch(error => console.log(error))
    },
    loadedCalendarData(data, once) {
      this.calendarJson = data
      this.events = data['events']
      if (!this.aside) {
        this.aside = this.events.find((e) => e.global === false)
      }
      this.loading -= 1
      if (once) {
        this.eventsFiltered = data['events']
      } else {
        this.refreshEventsFiltered()
      }
      this.$forceUpdate()
    },
    loadedAsideData(data) {
      this.asideJson = data
      this.loading -= 1
      this.$forceUpdate()
    },
    loadedMapStyleData(data) {
      this.mapStyle = data
      this.loading -= 1
      this.$forceUpdate()
    },
    loadedContentsData(data, once) {
      this.contentsJson = data['contents']
      if (!this.aside) {
        this.aside = this.contentsJson.find((e) => e.global === false)
      }
      if (once) {
        this.contentsFiltered = data['contents']
      } else {
        this.refreshEventsFiltered()
      }
      this.loading -= 1
      this.$forceUpdate()
    },
    refreshEventsFiltered () {
      if (this.$route.query && this.$route.query.event) {
        this.$router.replace({'query': null})
      }
      this.eventsFiltered = this.events.filter((i) => this.filterEvents.event ? (i[this.filterEvents.type + '_id'] === this.filterEvents.event.id && !i['global']) : i['global'])
      this.contentsFiltered = this.contentsJson.filter((i) => this.filterEvents.event ? (i[this.filterEvents.type + '_id'] === this.filterEvents.event.id && !i['global']) : i['global'])
      if (!this.contentsFiltered.length) {
        if (this.$route.path === '/contents') {
          this.$router.push({ path: this.calendar ? '/calendar' : '/map' })
        }
      }
    },
    moveSelector (el) {
      if(this.$refs.nav || el){
        if (el) {
          this.selectorWidth = el.target.offsetWidth + 'px'
          this.selectorLeft = el.target.offsetLeft + 'px'
        } else {
          let el = this.$refs.nav.querySelector('.router-link-active')
          if (el) {
            this.selectorWidth = el.offsetWidth + 'px'
            this.selectorLeft = el.offsetLeft + 'px'
          }
        }
      }
    },
    resetView () {
      this.eventsFiltered = this.events
      this.contentsFiltered = this.contentsJson
      if (this.$route.path !== '/map') {
        this.$router.push({ path: this.map ? '/map' : '/calendar' })
      }
    },
    callTagCo () {
      window.tc_vars.env_language = this.$i18n.locale
      window.tc_vars.env_template = this.$route.name
      window.tc_vars.page_name = this.$route.name
      window.tC.container.reload({ events: { pageview: [{}, {}] } })
      console.log('TAGCO => tc_vars: ', window.tc_vars)
    }
  }
}
</script>

<style lang="scss">
@import '~/src/assets/scss/main.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.slide-leave-active {
  transition: all 0.2s ease-in-out;
}
.slide-leave-to {
  opacity: 0;
  margin-top: -15rem;
}
</style>

<style lang="scss" scoped>
main {
  position: relative;
  flex-grow: 1;

  header {
    display: flex;
    width: 100%;
    height: 15rem;
    padding: 0 4rem;
    align-items: center;
    justify-content: space-between;

    &.-noHeader{
      height: 5rem;
    }

    @include bp-down(lg) {
      padding: 0 2rem;
      height: 4rem;

      &.-withContents {
        height: 12rem;
        nav {
          display: flex;
          margin: 4rem auto 2rem;

          .link-map,
          .link-calendar {
            display: none;
          }
        }
      }
    }

    .title {
      color: $primary;
      font-weight: 300;
      font-size: em(46);
      line-height: em(50, 46);
      @include bp-down(lg) {
        display: none;
        font-size: em(30);
        line-height: em(40, 30);
      }
    }

    nav {
      position: relative;
      display: flex;
      padding: .5rem;
      background: white;
      border-radius: 2.5rem;
      margin-left: auto;

      @include bp-down(lg) {
        display: none;
      }

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: .5rem;
        bottom: .5rem;
        border-radius: 2.5rem;
        left: var(--left);
        width: var(--width);
        transition: all 0.2s ease-in-out;
        background-color: $secondary;
        opacity: 0.1;
      }

      a {
        position: relative;
        display: block;
        padding: 1rem 2rem;
        color: $primary;
        opacity: 0.5;
        font-size: em(22);

        i {
          pointer-events: none;

          &.badge {
            position: absolute;
            top: 34%;
            left: 35%;
            font-style: normal;
            font-size: em(8, 22);
            font-weight: 500;
            border-radius: 100%;
            width: 1.2rem;
            height: 1.2rem;
            text-align: center;
            padding-top: .1rem;
            line-height: 1.2rem;
            color: white;
            background-color: $primary;
            transform: translate(-50%, -50%);
          }
        }

        &:hover, &.router-link-exact-active, &.router-link-active {
          opacity: 1;
        }
      }
    }
  }

  .views {
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    overflow: scroll;
    transition: all 0.2s ease-in-out;
    background-position: center center;
    background-size: cover;

    &.-full {
      width: calc(100% - 4rem);
      @include bp-down(lg) {
        width: 100%;
      }
    }

    &.-only {
      width: 100%;
      @include bp-down(lg) {
        width: 100%;
      }
    }

    @include bp-down(lg) {
      width: 100%;
      position: relative;
    }
  }
}

.flower-spinner,  .flower-spinner * {
  box-sizing: border-box;
}

.flower-spinner {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
}

.flower-spinner .dots-container {
  height: calc(70px / 7);
  width: calc(70px / 7);
}

.flower-spinner .smaller-dot {
  background: $primary;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;

}

.flower-spinner .bigger-dot {
  background: $primary;
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
}

@keyframes flower-spinner-bigger-dot-animation {
  0%, 100% {
    box-shadow: $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0;
  }

  50% {
    transform: rotate(180deg);
  }

  25%, 75% {
    box-shadow: $primary 26px 0 0,
    $primary -26px 0 0,
    $primary 0 26px 0,
    $primary 0 -26px 0,
    $primary 19px -19px 0,
    $primary 19px 19px 0,
    $primary -19px -19px 0,
    $primary -19px 19px 0;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0;
  }
}

@keyframes flower-spinner-smaller-dot-animation {
  0%, 100% {
    box-shadow: $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0;
  }

  25%, 75% {
    box-shadow: $primary 14px 0 0,
    $primary -14px 0 0,
    $primary 0 14px 0,
    $primary 0 -14px 0,
    $primary 10px -10px 0,
    $primary 10px 10px 0,
    $primary -10px -10px 0,
    $primary -10px 10px 0;
  }

  100% {
    box-shadow: $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0,
    $primary 0 0 0;
  }
}
</style>
