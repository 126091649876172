import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: "AIzaSyBz944Epj7xfMjhZN4qH_KFIOccFBqc1kk",
    libraries: 'geometry'
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
